<style scoped>
img {
  object-fit: cover;
}
div {
  box-sizing: border-box;
}
.top-tab {
  width: 100%;
  min-width: 1320px;
  height: 50px;
  background-color: #515c76;
  z-index: 999;
  opacity: 0.8;
  margin: -50px auto 0;
}

.tabInner {
  width: 1320px;
  padding: 0 400px;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  margin: 0 auto;
}

.tabInner > div {
  cursor: pointer;
}

.tab-active {
  position: relative;
}

.tab-active::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 50%;
  width: 30px;
  height: 2px;
  background: #fff;
  transform: translateX(-50%);
}

.content {
  width: 1320px;
  margin: 98px auto 0;
}

.con-title {
  text-align: center;
}

.title_div {
  width: 64px;
  height: 8px;
  background: #75b9f6;
  margin: 0 auto;
}

.title {
  margin: 50px 0;
  color: #222;
  font-size: 34px;
  font-weight: bold;
}

.con-text {
  color: #2e2e2e;
  font-size: 15px;
  padding: 0 10px;
  box-sizing: border-box;
}

.con-img {
  margin-top: 64px;
}

.imgBox-1 img {
  object-fit: cover;
}

.imgBox-1 > img:nth-child(1) {
  width: 820px;
  height: 400px;
}

.imgBox-1 > img:nth-child(2) {
  width: 420px;
  height: 400px;
}

.imgBox2Inner {
  width: 820px;
}

.imgBox-2 {
  margin: 78px 0 160px;
}

.imgBox-2 img {
  width: 420px;
  height: 324px;
}

.imgBox2Inner img {
  width: 370px;
  height: 324px;
}

.bottom-bg {
  width: 100%;
  min-width: 1320px;
  margin-top: 110px;
  background: #000;
}

.bottomInner {
  width: 1320px;
  height: 420px;
  margin: 0 auto;
  padding: 0 60px;
}

.bottomInner > div {
  height: 420px;
  width: calc(100% / 4);
}
.bottom-item {
  background-size: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.posi-bgColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.posi-bgColor:hover {
  background: #6da3d6;
  opacity: 0.8;
}

.bottom_item_img1 {
  width: 48px;
  height: 38px;
}
.bottom_item_img2 {
  width: 48px;
  height: 45px;
}
.bottom_item_img3 {
  width: 32px;
  height: 48px;
}
.bottom_item_img4 {
  width: 48px;
  height: 48px;
}
.bottom_div {
  width: 24px;
  height: 1px;
  background-color: #fff;
  margin: 26px 0;
}
.bottom-item > p {
  font-size: 15px;
  color: #fff;
}
/*  */
.content2 {
  width: 100%;
  min-width: 1320px;
  height: 2400px;
  background-image: url(../assets/images/company-img14.png);
  background-size: 100%;
}
.con2-box {
  width: 1320px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.con2-title {
  text-align: center;
  position: absolute;
  top: 111px;
  left: 50%;
  transform: translateX(-50%);
}
.con2-title > p:nth-child(1) {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  margin-bottom: 58px;
}
.con2-title > p:nth-child(2) {
  font-size: 15px;
  color: #666;
}
.con2-div-center {
  position: absolute;
  top: 334px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 58px;
  color: red;
  flex-direction: column;
 
}
.yuan-box {
  width: 100px;
  height: 100px;
  border: 1px solid #75b9f6;
  border-radius: 50%;
  margin-top: 232px;
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 5s; /*动画所花费的时间*/



}
.yuan-box-1 {
  width: 70px;
  height:70px;
  border: 1px solid #75b9f6;
  border-radius: 50%;

}
.yuan-box-2 {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background: #75b9f6;
  margin-top: -69px;

  
}
.xian {
  height:70%;
  width: 1px;
  background: #75b9f6;
  /* margin-top: -10px; */
  position: absolute;
  top: 280px;
}
.con2-text {
  position: absolute;
  width: 320px;
}
.con2-text1 {
  top: 363px;
  left: 50px;
}
.con2-date {
  font-size: 92px;
  color: #e6f3ff;
}
.con2-text-inner {
  margin-top: -32px;
}
.text-inner-1 {
  margin-bottom: 30px;
}
.text-inner-1 > p:nth-child(1) {
  font-size: 19px;
  font-weight: bold;
  color: #222;
}
.text-inner-1 > p:nth-child(2) {
  color: #707070;
  font-size: 14px;
  line-height: 20px;
  margin-top: 23px;
}
.con2-text2 {
  top: 1063px;
  right: 50px;
}
.con2-text3 {
  top: 1642px;
  left: 50px;
}
.con2-text4 {
  right: 50px;
  top: 1932px;
}
.bottom-list {
  width: 100%;
  z-index: 6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
0% {
        transform: scale(0);
        opacity: 0.3;
    }
    25% {
        transform: scale(0.5);
        opacity: 0.3;
    }
    50% {
        transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        transform: scale(0.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 0.0;
    }
}
</style>

<template>
  <div>
    <Top :topTitle="topTitle" :topTitle2="topTitle2"></Top>
    <div class="top-tab">
      <div class="tabInner flex-around flex-y-center">
        <div
          :class="{ 'tab-active': tabIndex == index }"
          v-for="(item, index) in ['公司简介', '发展历程']"
          :key="index"
          @click="tabIndex = index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="tabIndex == 0">
      <div class="content">
        <div class="con-title">
          <div class="title_div"></div>
          <div class="title">公司简介</div>
        </div>
        <div class="con-text" v-html="info.content"></div>
        <div class="con-img">
          <div class="imgBox-1 flex-between">
            <img src="../assets/images/company-img1.png" alt="" />
            <img src="../assets/images/company-img2.png" alt="" />
          </div>
          <div class="imgBox-2 flex-between">
            <div class="imgBox2Inner flex-between">
              <img src="../assets/images/company-img3.png" alt="" />
              <img src="../assets/images/company-img4.png" alt="" />
            </div>
            <img src="../assets/images/company-img5.png" alt="" />
          </div>
        </div>
        <div class="con-title">
          <div class="title_div"></div>
          <div class="title">企业文化</div>
          <div style="color: #666; font-size: 15px">{{ info.culture }}</div>
        </div>
      </div>
      <div class="bottom-bg">
        <div class="bottomInner flex">
          <div
            class="bottom-item flexCenter"
            :style="{ 'background-image': 'url(' + item.bg + ')' }"
            v-for="(item, index) in bottomBgList"
            :key="index"
          >
            <div class="bottom-list">
              <img :class="`bottom_item_img${index + 1}`" :src="item.icon" />
              <div class="bottom_div"></div>
              <p>{{ item.text }}</p>
              <p v-if="item.text_1">{{ item.text_1 }}</p>
              <p v-if="item.text_2">{{ item.text_2 }}</p>
            </div>
            <div class="posi-bgColor"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content2">
        <div class="con2-box">
          <div class="con2-title">
            <p>企业大纪事</p>
            <p>我们与社会各界企业携手共赢、创新奋进、创建美好的未来</p>
          </div>
          <div class="con2-div-center flex-y-center ">
            <div class="yuan-box flexCenter">
              <div class="yuan-box-1 flexCenter">
                
              </div>
            </div>
             <div class="yuan-box-2 flexCenter"></div>
            <div class="xian"></div>
            <div class="yuan-box flexCenter">
              <div class="yuan-box-1 flexCenter">
                
              </div>
            </div>
            <div class="yuan-box-2 flexCenter"></div>
            <div class="xian" ></div>
            <div class="yuan-box flexCenter">
              <div class="yuan-box-1 flexCenter">
                
              </div>
            </div>
            <div class="yuan-box-2 flexCenter"></div>
            <div class="xian"></div>
            <div class="yuan-box flexCenter">
              <div class="yuan-box-1 flexCenter">
               
              </div>
            </div>
             <div class="yuan-box-2 flexCenter"></div>
            <div class="xian"></div>
          </div>
          <div class="con2-text con2-text1">
            <div class="con2-date">2020</div>
            <div class="con2-text-inner">
              <div class="text-inner-1">
                <p>《生态校园》项目启动</p>
                <p>《生态校园》项目于2020年4月22日正式启动落地</p>
              </div>
              <div class="text-inner-1">
                <p>《生态校园疫情防控行动》公益项目</p>
                <p>钟南山院士担任《生态校园疫情防控行动》公益项目总顾问</p>
              </div>
            </div>
          </div>
          <div class="con2-text con2-text2">
            <div class="con2-date">2015</div>
            <div class="con2-text-inner">
              <div class="text-inner-1">
                <p>公司主营业务转向</p>
                <p>
                  因市场变化，公司主营业务方向转向互联网技术开发及微信运营。
                </p>
              </div>
            </div>
          </div>
          <div class="con2-text con2-text3">
            <div class="con2-date">2014</div>
            <div class="con2-text-inner">
              <div class="text-inner-1">
                <p>成立佑护科技科技有限公司</p>
                <p>深圳市佑护科技有限公司成立，主营电子商务。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom :minWidth="minWidth"></Bottom>
  </div>
</template>

<script>
import Top from "../../components/top.vue";
import Bottom from "../../components/bottom.vue";
export default {
  name: "CompanyIntroduction",
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem("topBottomInfo")),
      tabIndex: 0,
      minWidth: 1320,
      topTitle: "公司简介",
      topTitle2:
        "佑护科技是一家集成企业信息建设，忘了开发/互联网营销于一体的综合性IT公司。",
      bottomBgList: [
        {
          bg: require("../assets/images/company-img6.png"),
          icon: require("../assets/images/company-img10.png"),
          text: "传播",
          text_1: "提升关注度",
          text_2: "打动潜在客户",
        },
        {
          bg: require("../assets/images/company-img7.png"),
          icon: require("../assets/images/company-img11.png"),
          text: "品牌",
          text_1: "树立品牌形象",
          text_2: "增强信任感",
        },
        {
          bg: require("../assets/images/company-img8.png"),
          icon: require("../assets/images/company-img12.png"),
          text: "便捷",
          text_1: "快速交付",
          text_2: "节约大量时间人力",
        },
        {
          bg: require("../assets/images/company-img9.png"),
          icon: require("../assets/images/company-img13.png"),
          text: "访问",
          text_1: "高曝光率高精准度",
          text_2: "用户随时随地获取信息",
        },
      ],
    };
  },
  created() {
    // this.getSite();
  },
  methods: {
    getSite() {
      this.http({
        method: "get",
        url: this.api.get_site_info,
        data: {},
      }).then((res) => {
        this.info = res.data;
        this.topTitle2 = res.data.profile;
      });
    },
  },
  components: {
    Top,
    Bottom,
  },
};
</script>

<style>
</style>
